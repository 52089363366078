module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Candela borne photo","short_name":"Candela","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"minimal-ui","icon":"src/assets/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":false,"firestore":true,"storage":true,"messaging":false,"functions":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
